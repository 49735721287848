
import React, { useState } from 'react';
import './Popup.css'; 

function Popup({setloginpage,setIsAdmissionpage,isClose, setIsClose,inquiryNO}) {
  


  const handleClose = () => {
    setIsClose(true); 
    window.location.reload();
  };

  const loginHandler=(e)=>{
e.preventDefault();
setloginpage(true);
setIsAdmissionpage(false);
setIsClose(true); 
  }

  return (
    <div>
    {/* {isClose &&( */}
        <div className="popup-container">
         
          <div className="popup">
            <div>
            <span className="close-icon" onClick={handleClose}>
              &times;
            </span>
            <h2 className='Congratulations'>Congratulations!</h2>
            <p className='inquiry'>Your inquiry <span style={{fontWeight:'bold'}}>{`(${inquiryNO})`}</span> has been successfully submitted.</p>
            <button className='loginpopup' onClick={loginHandler}>Proceed to Login</button>
            </div>
          </div>
         
        
        </div>
      {/* )} */}
      </div>
  );
}

export default Popup;
