import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import './inquiryTable.css'
// Styled components for table cells and rows
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1181D2",
    color: theme.palette.common.white,
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
// Sample data with unique IDs
const rows = [
  {
    id: 1,
    inquiryDate: '01/01/2024',
    applicationNo: '1111',
    appliedProgram: 'yes',
    submittedOn: 'yes',
    fees: '1000',
    status: 'progress',
    percentage: 100,
  },
  // Additional rows with unique IDs
  {
    id: 2,
    inquiryDate: '02/01/2024',
    applicationNo: '2222',
    appliedProgram: 'no',
    submittedOn: 'no',
    fees: '2000',
    status: 'completed',
    percentage: 85,
  },
  {
    id: 3,
    inquiryDate: '03/01/2024',
    applicationNo: '3333',
    appliedProgram: 'yes',
    submittedOn: 'yes',
    fees: '1500',
    status: 'in review',
    percentage: 100,
  },
  {
    id: 4,
    inquiryDate: '04/01/2024',
    applicationNo: '4444',
    appliedProgram: 'no',
    submittedOn: 'no',
    fees: '1200',
    status: 'pending',
    percentage: 60,
  },
];
export default function Inquiry() {
  return (
    <div className="inquieryTableTop">
    <TableContainer  className="inquieryTableMid" component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{lineHeight:'16.8px',fontWeight:400,height:'16px'}} align="center">Inquiry Date</StyledTableCell>
            <StyledTableCell sx={{lineHeight:'16.8px',fontWeight:400,height:'16px'}} align="left">Application No.</StyledTableCell>
            <StyledTableCell sx={{lineHeight:'16.8px',fontWeight:400,height:'16px'}} align="left">Applied Program</StyledTableCell>
            <StyledTableCell sx={{lineHeight:'16.8px',fontWeight:400,height:'16px'}} align="left">Submitted On</StyledTableCell>
            <StyledTableCell sx={{lineHeight:'16.8px',fontWeight:400,height:'16px'}} align="left">Fees</StyledTableCell>
            <StyledTableCell sx={{lineHeight:'16.8px',fontWeight:400,height:'16px'}} align="left">Status</StyledTableCell>
            <StyledTableCell sx={{lineHeight:'16.8px',fontWeight:400,height:'16px'}} align="left">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell sx={{lineHeight:'16.8px',fontWeight:400, height:'16px'}} align="center">{row.inquiryDate}</StyledTableCell>
              <StyledTableCell sx={{lineHeight:'16.8px',fontWeight:400, height:'16px'}}  align="left">{row.applicationNo}</StyledTableCell>
              <StyledTableCell sx={{lineHeight:'16.8px',fontWeight:400, height:'16px'}} align="left">{row.appliedProgram}</StyledTableCell>
              <StyledTableCell sx={{lineHeight:'16.8px',fontWeight:400, height:'16px'}} align="left">{row.submittedOn}</StyledTableCell>
              <StyledTableCell sx={{lineHeight:'16.8px',fontWeight:400, height:'16px'}} align="left">{`${row.fees}`}</StyledTableCell>
<StyledTableCell
  className={`${row.percentage === 100 ? 'new' : 'old'}`}
  sx={{ lineHeight: '16.8px', fontWeight: 400, height: '16px' }}
  align="left"
>
  {`${row.status} (${row.percentage}%)`}
</StyledTableCell>              <StyledTableCell sx={{lineHeight:'16.8px',fontWeight:400, height:'16px'}} align="right">
                <Button variant="contained" sx={{borderRadius:'4px', padding:'8px 24px',fontSize:'14px',height:"30px",width:"100%",backgroundColor: '#1181D2'}}>View</Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  );
}









