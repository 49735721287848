import React, { useState } from 'react';
import './Passwordreset.css';
import { Container, Typography, TextField, Button, Box, Link, IconButton, InputAdornment } from '@mui/material';
import resetpasswordicon from '../images/resetpassword.svg';
import Passwordconfirmed from './Passwordconfirmed';
import passwordhideicon from '../images/passwordhideicon.svg';
import passwordshowicon from '../images/passwordshowicon.svg';

function Passwordreset() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [isPasswordconfirmedpage, setIsPasswordconfirmedpage] = useState(false);
  const [isForgotpasswordotppage, setIsForgotpasswordotppage] = useState(true)

  const handleClickShowPassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleClickShowConfirmPassword = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handleResetPassword = () => {
    if (password !== confirmPassword) {
      setPasswordError(true);
      setConfirmPasswordError(true);
      return;
    }
    setIsPasswordconfirmedpage(true);
    setIsForgotpasswordotppage(false);
  };

  return (
    <div>
      {isForgotpasswordotppage ? (<div className="forgotpasswordnmain">
        <Container maxWidth="sm" className="forgotPasswordContainer">
          <Box textAlign="left" mb={4}>
            <Typography variant="h4" color="#1181D2">Reset Password</Typography>
            <Typography variant="body1" color="#102048">Set a new password</Typography>
          </Box>
          <Box textAlign="center" mb={4}>
            <img src={resetpasswordicon} alt="Reset Icon" style={{ width: '100px', height: 'auto' }} />
          </Box>
          <Box mb={2} p={'10.5px 14px'}>
            <label htmlFor='password'>Password</label>
            <TextField
              fullWidth
              type={passwordVisible ? "text" : "password"}
              id="password"
              placeholder="Enter New Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setPasswordError(false);
              }}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={handleClickShowPassword}
                    >
                      <img src={passwordVisible ? passwordhideicon : passwordshowicon}  />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box mb={2} p={'10.5px 14px'}>
            <label htmlFor='confirmPassword'>Confirm Password</label>
            <TextField
              fullWidth
              error={confirmPasswordError}
              type={confirmPasswordVisible ? "text" : "password"}
              id="confirmPassword"
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setConfirmPasswordError(false);
              }}
              helperText={confirmPasswordError ? "Passwords don't match" : ""}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={handleClickShowConfirmPassword}
                    >
                      <img src={confirmPasswordVisible ? passwordhideicon : passwordshowicon}  />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ height: 40, borderRadius: 2, fontSize: '1rem' }}
            onClick={handleResetPassword}
          >
            Reset Password
          </Button>
          <Box textAlign="center" mt={4}>
            <Typography variant="body2" color="#102048">
              For an optimal user experience with Proctur, we recommend using{' '}
              <Link href="#" color="inherit" fontWeight="bold">Google Chrome</Link>.
            </Typography>
          </Box>
        </Container>
      </div>):(<Passwordconfirmed />)}
      
    </div>
  );
}

export default Passwordreset;
