import React, { useState } from 'react'
import Navbar from '../NavBar/NavBar'
import HeroSection from '../HeroSection/HeroSection'
import Courses from '../OfferedCourses/OfferedCourses'
import Instruction from '../Instructions'
import NewAdmissionProcess from '../NewAdmissionProcess/newAdmissionProcess'
import OurCampus from '../OurCampus/ourCampus'
import Footer from '../Footer/Footer'
import Slideshow from '../Recruiters/Slideshow'
import Loader from '../loader/Loader'
import style from './HomePage.module.css'
import { Height } from '@mui/icons-material'

const HomePage = () => {
  const [availableShowHide,setAvailableShowHide]=useState(false)
const [showHideLoader,setShowHideLoader]=useState(false)
  const courseHeightClick=(value)=>{
    setAvailableShowHide(value)
  }
  return (
    <div className={style.homePage}>
{/* {showHideLoader && <Loader/>} */}
<Navbar />
 
        <HeroSection showHideLoader={showHideLoader} setShowHideLoader={setShowHideLoader} courseHeightClick={courseHeightClick} />
        
        <Courses availableShowHide={availableShowHide} />
        <Instruction />
        <NewAdmissionProcess />
        <OurCampus />
        <Slideshow/>
        <Footer />
    </div>
  )
}

export default HomePage