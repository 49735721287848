import React, { useState } from 'react';
import {
    TextField,
    Grid,
    Typography,
    Box,
    MenuItem,
    FormControl,
    Select,
} from '@mui/material';

const InputDetails = ( {htmlFor,type,lableName,className,error=false,value, setStateData,star}) => {
    // const [name, setName] = useState("");
    // const [nameError, setNameError] = useState(false);

  return (
    <> 
    <label htmlFor={htmlFor}>
                        {lableName} {star && <span style={{ color: "red", marginLeft: "4px" }}>*</span>}
                    </label>
                    <TextField className={className}
                        error={error}
                        type={type}
                        id={htmlFor}
                        value={value}
                        onChange={(e) => setStateData(htmlFor, e.target.value)}
                        placeholder={`Enter ${lableName}`}
                        helperText={error ? `Please enter valid ${lableName}` : ""}
                        size="small"
                        fullWidth
                        required
                        InputLabelProps={{ shrink: false }}
                    />
 </>
  )
}

export default InputDetails