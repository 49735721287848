import React from 'react';
import './Sidebar.css';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const Sidebar = () => {
  const [programe, setPrograme] = React.useState('B.Tech (Computer Science)');

  const handleChangeProgram = (event) => {
    setPrograme(event.target.value);
  };
  return (
    <div className="sidebar">
    <img src="https://via.placeholder.com/150" alt="Profile" className="profile-pic" />
    <h4>Akash Kumar Jaiswal</h4>
    <p>Inquiry No: EN24432</p>
    <p style={{color:'#7F7F7F'}}>akjaiswal@gmail.com</p>
    <p style={{color:'#7F7F7F'}}>+91-8888888888</p>
    <div style={{borderBottom:'2px solid #E1E1E1',marginTop:'20px'}}/>
    <div className="academic-details">
      <h3>Academic Year</h3>
      <p><span className='dotStyle'/>2024-2025</p>
      {/* <h3>Program/Branch</h3> */}
   


  

 
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Program/Branch</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
         
          value={programe}
          label="program"
          onChange={handleChangeProgram}
          sx={{height:26,color:'black',fontWeight:500,textAlign:'left',backgroundColor:'#f3f8fc'}}
        >
          <MenuItem value={'B.Tech (Computer Science)'}>B.Tech (Computer Science)</MenuItem>
          <MenuItem value={'BSc'}>BSc</MenuItem>
          <MenuItem value={'MSc'}>MSc</MenuItem>
          <MenuItem value={'Bca'}>BCa</MenuItem>
        </Select>
      </FormControl>
    </Box>
  

      <p><span className='dotStyle stage_status'/>{programe}</p>
      <h3>Stage/Status</h3>
      <p><span className='dotStyle'/>Registration</p>
     <div className='mainstatus'>
      <div className="status">
      <div className="status-in-progress">In Progress</div>
      </div>
      <h5>(75%)</h5>
     </div>
    </div>
  </div>
  );
};

export default Sidebar;
