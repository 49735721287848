import React from "react";
import instructionImg from "../images/Instructions.svg";
import "./instruction.css";
const Instruction = () => {
  return (
    <>
      <div className="instruction" style={{ padding: "56px 72px 56px 72px" }}>
        <span
          style={{
            fontSize: "28px",
            fontWeight: "600",
            Font: "Roboto",
            color: "#102048",
          }}
        >
          INSTRUCTION GUIDELINES
        </span>
        <div className="midSection" >
          <img height={570} style={{ height: "485px",width:'100%' }} src={instructionImg} />
          <div className="instructions">
            <h2
              style={{
                textAlign: "start",
                Font: "Roboto",
                fontWeight: "600",
                color: "#242A59",
              }}
            >
              Admission Guidelines for the Academic Year 2024-25 
            </h2>
            <p
              style={{
                textAlign: "start",
                Font: "Roboto",
                fontWeight: "400",
                color: "#242A59",
              }}
            >
              Candidates seeking admission for the 2024-25 academic year must
              adhere to the following guidelines. The admission process includes
              completing the admission form at the institute and presenting the
              necessary documents for verification.
            </p>
            <ul
              style={{
                paddingLeft: "2px",
                Font: "Roboto",
                fontWeight: "400",
                color: "#242A59",
              }}
            >
              <li>
                <b>Complete Forms Only:</b> Incomplete forms will NOT be
                accepted.
              </li>
              <li>
                <b>Document Preparation:</b> Have all necessary documents ready
                before filling out the form.
              </li>
              <li>
                <b>Contact Information:</b> Provide an active mobile number and
                email ID for further communication.
              </li>
              <li>
                <b>Registration Number:</b> Always mention your registration
                number in any communication; it will also be your user ID.
              </li>
              <li className="hideInstructions">
                <b>Mandatory Fields:</b> Fill all sections marked with an
                asterisk (*).
              </li>
              <li className="hideInstructions">
                <b>Form Submission:</b> Use the ‘Next’ button to save each page
                and click ‘Finish’ to submit the form.
              </li>
            </ul>
            <div className="contact hideInstructions">
              <p
                style={{
                  textAlign: "start",
                  Font: "Roboto",
                  fontWeight: "400",
                  color: "#242A59",
                }}
              >
                For any assistance, please contact:
                <ul
                  style={{
                    paddingLeft: "2px",
                    Font: "Roboto",
                    fontWeight: "400",
                    color: "#242A59",
                  }}
                >
                  <li>
                    Ms. Roopanjali at 
                    <a href="tel:+919971600288">+91-9971600288</a>{" "}
                  </li>
                  <li>
                    Mr. Nirdosh at {" "}
                    <a href="tel:+918527017776">+91-8527017776</a>
                  </li>
                  <li>
                    Email:
                    <a href="enquirieshrit@gmail.com">
                       enquirieshrit@gmail.com
                    </a>
                  </li>
                </ul>
              </p>
              <p
                style={{
                  textAlign: "start",
                  Font: "Roboto",
                  fontWeight: "400",
                  color: "#242A59",
                }}
              >
                We look forward to your application and wish you the best of
                luck in the admission
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Instruction;
