import React, { useState } from 'react'
import { Container, Typography, TextField, Button, Box, Link } from '@mui/material'
import emailicon from '../images/emailicon.svg'
import Forgotpasswordotp from './Forgotpasswordotp';
import './Forgotpassword.css'

function Forgotpassword() {
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState(false)
  const [isForgotpasswordpage, setIsForgotpasswordpage] = useState(true)
  const [isForgotpasswordotppage, setIsForgotpasswordotppage] = useState(false)

 const otpsubmitbtn = (event ) =>{
  event.preventDefault()
  let valid = true
  if (email === '') {
    setEmailError(true)
    valid = false
  } else {
    setEmailError(false)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(email)) {
      setEmailError(true)
      valid = false
    } else {
      setEmailError(false)
    }
   
  }
  
    setIsForgotpasswordpage(false)
    setIsForgotpasswordotppage(true)
  
 }
 

  return (
    <div>
      {isForgotpasswordpage ? ( <div className='forgotpasswordmain'>
      <Container maxWidth="sm" className='forgotPasswordContainer'>
        <Box textAlign="left" mb={4}>
          <Typography variant="h4" color="#1181D2">Forgot Password</Typography>
          <Typography variant="body1" color="#102048">Please enter your email to reset the password</Typography>
        </Box>
        <Box textAlign="center" mb={4}>
          <img src={emailicon} alt="Email Icon" style={{ width: '100px', height: 'auto' }} />
        </Box>
        <Box mb={4} p={'10.5px 14px'}>
        <label htmlFor="email">User Id</label>
          <TextField
            error={emailError}
            fullWidth
            type="email"
            id="email"
            placeholder="Enter Email"
            helperText={emailError ? 'Please enter valid email' : ''}
            value={email} 
            onChange={(e) => setEmail(e.target.value)}
            required 
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ height: 40, borderRadius: 2, fontSize: '1rem' }}
          onClick={otpsubmitbtn} 
        >
          Send OTP
        </Button>
        <Box textAlign="center" mt={4}>
          <Typography variant="body2" color="#102048">
            For an optimal user experience with Proctur, we recommend using{' '}
            <Link href="#" color="inherit" fontWeight="bold">Google Chrome</Link>.
          </Typography>
        </Box>
      </Container>
    </div>):(<Forgotpasswordotp/>)}
   
    </div>
  )
}

export default Forgotpassword
