import React from "react";
import "./ourCampus.css";

const Card = ({ title, secondname }) => {
  return (
    <div className="card-style-ourcompus">
      <div className="textContainer">
        <span className="card-title-ourcompus">{title}</span>
        <span className="card-title-ourcompus">{secondname}</span>
      </div>
    </div>
  );
};

export default Card;
