import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/NavBar/NavBar";
import Hero from "./components/HeroSection/HeroSection";
import RegistrationForm from "./components/AdmissionForm/AdmissionForm";
import "./App.css";
import Courses from "./components/OfferedCourses/OfferedCourses";
import Admissionform from "./components/AdmissionForm/AdmissionForm";
// import Recruiters from "./components/Recruiters/Slideshow";
import Instruction from "./components/Instructions";
import NewAdmissionProcess from "./components/NewAdmissionProcess/newAdmissionProcess";
import OurCampus from "./components/OurCampus/ourCampus";
import Footer from "./components/Footer/Footer";
// import Dashboard from "./components/dashboard/Dashboard";
import HomePage from "./components/homePage/HomePage";
import Dashboard from "./components/afterLogin/dashboard/Dashboard";
import ForgotPassword from "./components/Forgotpassword/Forgotpassword";

// Import your actual Dashboard component
// Update this path as needed

function App() {
  return (
    <Router>
      <div className="App">
        {/* <Navbar />
        <Hero />
        <Courses />
        <Instruction />
        <NewAdmissionProcess />
        <OurCampus />
        <Recruiters />
        <Footer /> */}

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/dashboard" element={<Dashboard/>} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
