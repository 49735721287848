import axios from "axios";
import config from "./config";
import URL from "./url";

const api = {
  get: (url) => {
    console.log("url-",URL[config.env].PROCTUR_PRODUCT_API_URL + url)
    return axios.get(URL[config.env].PROCTUR_PRODUCT_API_URL + url)},
  getAuth: (request) =>
    axios.get(URL[config.env].PROCTUR_PRODUCT_API_URL + request.url, {
      headers: {
        Authorization: `Bearer ${request.token}`,
        "Content-Type": "application/json",
        Source: "WEB",
      },
    }),
  post: (request) =>
    axios.post(
      URL[config.env].PROCTUR_PRODUCT_API_URL + request.url,
      request.data
    ),

    postSubmit: (request) =>
      axios.post(
        URL[config.env].PROCTUR_PRODUCT_API_URL + request.url,
        request.data,{ headers: {  Authorization: `Bearer ${request.token}`,
        "Content-Type": "application/json",
        Source: "WEB", },}
      ),

  postOTP: (request) =>
    axios.post(URL[config.env].PROCTUR_OTP_API_URL + request.url, request.data,
      {
        headers: {  Authorization: `Bearer ${request.token}`,
        "Content-Type": "application/json",
        Source: "WEB", },
      }
    ),

  put: (request) =>
    axios.put(
      URL[config.env].PROCTUR_PRODUCT_API_URL + request.url,
      request.data
    ),
  putAuth: (request) =>
    axios.put(
      URL[config.env].PROCTUR_PRODUCT_API_URL + request.url,
      request.data,
      {
        headers: { Authorization: request.token, Source: "WEB" },
      }
    ),
};

export default api;
