import React, { useState, useEffect } from 'react';
import "./Slideshow.css"
import img1 from "../images/1.png"
import img2 from "../images/2.png"
import img3 from "../images/3.png"
import img4 from "../images/4.png"
import img5 from "../images/5.png"
import img6 from "../images/6.png"
import img8 from "../images/8.png"
import img9 from "../images/9.png"
import img10 from "../images/10.png"
import img11 from "../images/11.png"
import img12 from "../images/12.png"
import img13 from "../images/13.png"
import img14 from "../images/14.png"
import img15 from "../images/15.png"
function Slideshow() {


  return (
    <div className="wrapper">
        <h1>OUR TOP RECRUITERS</h1>
    <div className="photobanner">
      <img
        className="first"
        src={img1}
        alt=""
      />
      <img
        src={img2}
        alt=""
      />
      <img
        src={img3}
        alt=""
      />
      <img
        src={img4}
        alt=""
      />
      <img
        src={img5}
        alt=""
      />
      <img
        src={img6}
        alt=""
      />
      <img
        src={img8}
        alt=""
      />
      <img
        src={img9}
        alt=""
      />
       <img
        src={img10}
        alt=""
      />
       <img
        src={img11}
        alt=""
      />
       <img
        src={img12}
        alt=""
      />
       <img
        src={img13}
        alt=""
      />
        <img
        src={img14}
        alt=""
      />
       <img
        src={img15}
        alt=""
      />

    </div>
  </div>
  

  );
}

export default Slideshow;