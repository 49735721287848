import React, { useState } from 'react'
import { Container, Typography, TextField, Button, Box, Link } from '@mui/material'
import emailotpicon from '../images/emailotpicon.svg'
import Passwordreset from './Passwordreset';
import './Forgotpasswordotp.css'

function Forgotpassword() {
  const [otp, setOtp] = useState("")
  const [otpError, setOtpError] = useState(false)
  const [isPasswordresetpage, setIsPasswordresetpage] = useState(false)
  const [isForgotpasswordotppage, setIsForgotpasswordotppage] = useState(true)

 const verifybtn = (event ) =>{
  event.preventDefault()
  let valid = true
  
    setIsPasswordresetpage(true)
    setIsForgotpasswordotppage(false)
  
 }
 

  return (
    <div>
      {isForgotpasswordotppage ? ( <div className='forgotpasswordmain'>
      <Container maxWidth="sm" className='forgotPasswordContainer'>
        <Box textAlign="left" mb={4}>
          <Typography variant="h4" color="#1181D2">Forgot Password</Typography>
          <Typography variant="body1" color="#102048">Please enter your email to reset the password</Typography>
        </Box>
        <Box textAlign="center" mb={4}>
          <img src={emailotpicon} alt="Email Icon" style={{ width: '100px', height: 'auto' }} />
        </Box>
        <Box mb={4} p={'10.5px 14px'}>
        <label>OTP</label>
          <TextField
            error={otpError}
            fullWidth
            type="text"
            placeholder="Enter OTP"
            helperText={otpError ? 'Please enter valid otp' : ''}
            value={otp} 
            onChange={(e) => setOtp(e.target.value)}
            required 
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ height: 40, borderRadius: 2, fontSize: '1rem' }}
          onClick={verifybtn} 
        >
          Verify
        </Button>
        <Box textAlign="center" mt={4}>
          <Typography variant="body2" color="#102048">
            For an optimal user experience with Proctur, we recommend using{' '}
            <Link href="#" color="inherit" fontWeight="bold">Google Chrome</Link>.
          </Typography>
        </Box>
      </Container>
    </div>):(<Passwordreset/>)}
   
    </div>
  )
}

export default Forgotpassword
