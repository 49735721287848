const URL = {
  dev: {
    PROCTUR_PRODUCT_API_URL: "http://20.40.47.53:8081/funnel_api/v1/",
    PROCTUR_OTP_API_URL: "http://20.40.47.53:8080",
  },
  sit: {},
  prod: {},
};

export default URL;
