import React,{useState} from 'react'
import rightcheck from '../images/Right Check.svg';
import { useNavigate } from 'react-router-dom';
// import './Passwordconfirmed.css'
import './Passwordreset.css'
function Passwordconfirmed() {
  const navigate = useNavigate();
  
  const handlebacktologinbtn = () => {
    navigate('/');
  }
  return (
    <div className='forgotpasswordnmain'>
    <div className='forgotPasswordContainer'>
         <div className='passwordheader'>
            <img src={rightcheck}></img>
            <h1>Congratulations!</h1>
            <p>Congratulations your password has been successfully changed. Continue to login</p>
         </div>
         <div className='passwordcontainer'>
           
            <div className='passwordinput'>
            <button onClick={handlebacktologinbtn}>Back to Login</button>
            </div>
         </div>
        
    </div>
    </div>
    
   
  )
}

export default Passwordconfirmed