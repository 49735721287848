import React, { useState } from "react";
import "./Login.css";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Admissionform from "./AdmissionForm";
import Link from '@mui/material/Link';
import api from "../../api/api";
import { useNavigate } from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
function Loginform({ setShowHideLoader, courseHeightClick }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isloginpage, setloginpage] = useState(true);
  const [isAdmissionpage, setIsAdmissionpage] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false); 

  const navigate = useNavigate();
 const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailPattern.test(email)
  }
  const handleloginpage = () => {
    courseHeightClick(true);
    setloginpage(true);
    setIsAdmissionpage(false);
  }

  const handleAdmissionpage = () => {
    courseHeightClick(false);
    setloginpage(false);
    setIsAdmissionpage(true);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    let valid = true;

    if (email === '') {
      setEmailError(true)
      valid = false
    } else {
      setEmailError(false)
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!emailRegex.test(email)) {
        setEmailError(true)
        valid = false
      } else {
        setEmailError(false)
      }
    }
    

    if (password === "") {
      setPasswordError(true);
      valid = false;
    } else {
      setPasswordError(false);
    }

    if (valid) {
      doSubmit();
    }
  }

  const doSubmit = () => {
    let request = {
      url: "Authentication/token",
      data: {
        username: email,
        password: btoa(password)
      },
    };
    setShowHideLoader(true);
    api.post(request)
      .then((res) => {
        if (res.data.success) {
          navigate('/dashboard');
        }

        setShowHideLoader(false);
      })
      .catch((error) => {
        setPasswordError(true);
        setShowHideLoader(false);
      });
  };
  const handleBlur = (event) => {
    const value = event.target.value
    setEmail(value)
    if (!validateEmail(value)) {
      setEmailError(true)
    } else {
      setEmailError(false)
    }
  }
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const forgotPasswordPage = () => {
    navigate('/forgotpassword');
  };

  return (
    <div>
      {isloginpage ? (
        <div className="containloginformMain">
          <div className="containloginform">
            <div className="loginFormLoginBtnMain">
              <button type="button" id="LoginFormRegisterBtn" onClick={handleAdmissionpage}>
                Register
              </button>
              <button type="button" id="LoginFormLoginBtn" onClick={handleloginpage}>
                Login
              </button>
            </div>
            <Box className="loginbox">
              <div className="loginformdetails">
                <div className="loginform-group">
                  <label htmlFor="email">User Name</label>
                  <TextField
                    error={emailError}
                    type="email"
                    id="email"
                    className="inputfieldlogin"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    onBlur={handleBlur}
                    placeholder="Enter User Name"
                    helperText={emailError ? "Enter Valid User Name" : ""}
                    size="small"
                    required
                  />
                </div>
                <div className="loginform-group">
                  <label htmlFor="password">Password</label>
                  <TextField
                    error={passwordError}
                    type={showPassword ? "text" : "password"}
                    id="password"
                    className="inputfieldlogin"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    placeholder="Enter password"
                    helperText={passwordError ? "Enter Valid Password" : ""}
                    size="small"
                    sx={{ width: 200}}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility />  :<VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <span
                    style={{
                      color: "#1181D2",
                      fontSize: "12px",
                      display: 'flex',
                      justifyContent: 'end',
                    }}
                  >
                  <Link href="#" underline="none"  onClick={forgotPasswordPage}>Forgot Password?</Link>
                  </span>
                </div>
              </div>

              <div style={{ display: 'grid' }} className="loginMainButton">
                <button type="submit" onClick={handleSubmit}>Login</button>
              </div>
            </Box>
          </div>
        </div>
      ) : (<Admissionform setShowHideLoader={setShowHideLoader} courseHeightClick={courseHeightClick} />)}
    </div>
  );
}

export default Loginform;
