import React from "react";
import Card from "./card";
import "./ourCampus.css";

const OurCampus = () => {
  const institutesList = [
    { id: 1, name: "H.R Institute of Technology", secondname: "" },
    { id: 2, name: "H.R Institute of Pharmacy", secondname: "" },
    { id: 3, name: "H.R Institute of Hotel Management", secondname: "" },
    {
      id: 4,
      name: "H.R Institute of Engineering & Technology",
      secondname: "",
    },
    { id: 5, name: "H.R. Institute of Science & Technology", secondname: "" },
    { id: 6, name: "H.R Institute of Law", secondname: "" },
    { id: 7, name: "Delhi Public School ", secondname: "(HRIT Campus)" },
  ];

  return (
    <div className="main-ourCampus">
      <h1 className="headerStyle">OUR CAMPUS</h1>
      <div className="list-container">
        {institutesList.map((item) => (
          <Card title={item.name} secondname={item.secondname} key={item.id} />
        ))}
      </div>
    </div>
  );
};

export default OurCampus;
