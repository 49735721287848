import React from "react";
import Faceboook from "../../components/images/facebook.png";
import Linkdin from "../../components/images/linkdin.png";
import Youtube from "../../components/images/youtube.png";
import Instagram from "../../components/images/instagram.png";
import procturlogo from "../images/procturlogo.png";
import home from "../images/home.png";
import email from "../images/email.png";
import path from "../images/Path.png";
import "./Footer.css";
import { YouTube } from "@mui/icons-material";
const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-content">
        <div className="footer-section university">
          <h1>HRIT</h1>
          <h3>UNIVERSITY</h3>
          <p>
            HRIT Group excels academically, supported by alumni and top
            companies, founded by Dr. Anil Agarwal, MP, offering diverse
            courses.
          </p>
          <div className="social-icons">
            <a
              href="https://www.facebook.com/Hritgroupofinstitutions/"
              target="_blank"
            >
              <img style={{ width: "44px", height: "44px" }} src={Faceboook} />
            </a>

            <a
              href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQF3VeyNcJouyQAAAZBeGXDgG6ZN-aRz1pOSY-t5kgS5siszz9UMSxMOdjoh2WBAAB0OwRGgk332O_jyCjv10oJE93cPUmc3ZAZEkt3dKXuSKhMIlTl3CDI4fP7thtE4Lmtu778=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fhr-institute-of-technology
              "
              target="_blank"
            >
              <img style={{ width: "44px", height: "44px" }} src={Linkdin} />
            </a>

            <a
              href="https://www.instagram.com/hritgi194/?igshid=YmMyMTA2M2Y%3D"
              target="_blank"
            >
              <img style={{ width: "44px", height: "44px" }} src={Instagram} />
            </a>

            <a
              href="https://www.youtube.com/@hrituniversity9606"
              target="_blank"
            >
              <img style={{ width: "44px", height: "44px" }} src={Youtube} />
            </a>
          </div>
        </div>
        <div className="linkUl">
          <h4>Link</h4>
          <ul>
            
            <li>Contact</li>
            <li>About Us</li>
            <li>Privacy Policy</li>
            <li>Terms and Conditions</li>
          </ul>
        </div>
        <div className="addressUl">
          <h4>Address</h4>
          <ul>
            <li>
              {/* <IoHomeOutline className="adressicon" /> */}
              <img src={home} style={{ height: "18px", width: "22px" }} />
              <span style={{ marginLeft: "4px" }}>
                {" "}
                8th kilometer, Milestone Delhi, Meerut Rd, Jalalabad 0069 Morta,
                Ghaziabad, Uttar Pradesh 201003{" "}
              </span>
            </li>
            <li>
              <img src={path} style={{ height: "18px", width: "22px" }} />
              <span style={{ marginLeft: "4px" }}> 9971600288</span>
            </li>
            <li>
              <img src={email} style={{ height: "18px", width: "22px" }} />
              <span style={{ marginLeft: "4px" }}>
                {" "}
                enquirieshrit@gmail.com
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        {/* <p>Copyright © 2016 - 2017 Hrit.ac.in. All rights reserved.</p> */}
        <img
          style={{
            backgroundColor: "#FAFAFA",
            height: 51,
            width: 120,
            borderRadius: 5,
          }}
          src={procturlogo}
        ></img>
      </div>
    </div>
  );
};

export default Footer;
