import React, { useState, useEffect } from "react";
import "./HeroSection.css";
import img1 from "../images/hritherosectionimg.png";
import img2 from "../images/hritgroupofinstitutions.png";
import img3 from "../images/hritherosectionimg.png";
import img4 from "../images/hritgroupofinstitutions.png";
import modiji from "../images/modiji.png";
import admissionImg from "../images/admissionlinkimg.png";
import Admissionform from "../AdmissionForm/AdmissionForm";

const HeroSection = ({showHideLoader,setShowHideLoader,courseHeightClick}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showForm, setShowForm] = useState(true);
  const images = [modiji, img3];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [images.length]);

  const handleDotClick = (index) => {
    setCurrentImageIndex(index);
  };

  useEffect(() => {
    console.log("Current Image Index: ", currentImageIndex);
  }, [currentImageIndex]);

  return (
    <section className="hero-section">
      <div className="heroSectionMid"
        
      >
    

        {showForm && <Admissionform showHideLoader={showHideLoader} setShowHideLoader={setShowHideLoader} courseHeightClick={courseHeightClick} />}
      </div>

      <img src={images[currentImageIndex]} alt="Hero Image" className="hero-image" />

      <div className="dots">
        {images.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentImageIndex ? "active" : ""}`}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </div>
    </section>
  );
};

export default HeroSection;
