import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import './AdmissionForm.css'
import captchaimg from '../images/captchaimg.png'
import camelize from './../../../node_modules/dom-helpers/esm/camelize'
import refresh from '../images/refersh.png'
import captchacode from '../images/captch.png'
import varified from '../../components/images/Checkbox.png'
import api from '../../api/api'
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha'
import Login from './Login'
import Popup from '../popupmsg/Popup'
import Loader from '../loader/Loader'
function Admissionform({showHideLoader, courseHeightClick, setShowHideLoader }) {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [otp, setOtp] = useState('')
  const [lableNameOtp, setLableNameOtp] = useState('Send OTP')
  const [product, SetProduct] = useState([])
  const [cityState, setCityState] = useState([])
  const [particularCity, setParticularCity] = useState([])
  const [courses, setCourses] = useState([])
  const [cityList, setCityList] = useState([])
  const [higherQualification, setHigherQualification] = useState('')
  const [state, setState] = useState('')
  const [varifyOtp, setVarifyOtp] = useState(false)
  const [city, setCity] = useState('')
  const [courseApplied, setCourseApplied] = useState('')
  const [captcha, setCaptcha] = useState('')
  const [captchErrorMessgeShow, setCaptchErrorMessgeShow] = useState('')
  const [accessKey, setAccessKey] = useState('')
  const [submitErrorMessgeShow, setSubmitErrorMessgeShow] = useState('')
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [countdown, setCountdown] = useState(12)
  const [isDisabled, setIsDisabled] = useState(false)
  const [isloginpage, setloginpage] = useState(false)
  const [isAdmissionpage, setIsAdmissionpage] = useState(true)
  const [nameError, setNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [mobileError, setMobileError] = useState(false)
  const [otperror, setOtperror] = useState(false)
  const [productError, setProductError] = useState(false)
  const [cityError, setCityError] = useState(false)
  const [stateError, setStateError] = useState(false)
  const [courseAppliedError, setCourseAppliedError] = useState(false)
  const [captchaError, setCaptchaError] = useState(false)
  const [checkboxError, setCheckboxError] = useState(false)
  const [particularProgram, setParticularProgram] = useState([])
  const [isClose, setIsClose] = useState(true)
  const [inquiryNO, setInquiryNO] = useState('')

  var timer

  const handleSubmit = (event) => {
    event.preventDefault()
    let valid = true

    if (name === '') {
      setNameError(true)
      valid = false
    } else {
      setNameError(false)
    }

    if (higherQualification === '') {
      setProductError(true)
      valid = false
    } else {
      setProductError(false)
    }

    if (courseApplied === '') {
     
      setCourseAppliedError(true)
      valid = false
    } else {
 
      setCourseAppliedError(false)
    }

    if (city === '') {
      setCityError(true)
      valid = false
    } else {
      setCityError(false)
    }

    if (state === '') {
      setStateError(true)
      valid = false
    } else {
      setStateError(false)
    }

    // if (captcha === "") {
    //   setCaptchaError(true);
    //   valid = false;
    // } else {
    //   setCaptchaError(false);
    // }

    if (captcha === '') {
      setCaptchaError(true)
      setCaptchErrorMessgeShow('Please Enter Valid Captcha')
      valid = false
    } else {
      setCaptchaError(false)
      if (!validateCaptcha(captcha)) {
        setCaptchaError(true)
        setCaptchErrorMessgeShow('Captcha is not matching')
        valid = false
      } else {
        setCaptchaError(false)
        setCaptchErrorMessgeShow('')
      }
    }
    if (email === '') {
      setEmailError(true)
      valid = false
    } else {
      setEmailError(false)
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!emailRegex.test(email)) {
        setEmailError(true)
        valid = false
      } else {
        setEmailError(false)
      }
    }

    if (mobileNumber === '') {
      setMobileError(true)
      valid = false
    } else {
      setMobileError(false)
      const mobileNumberRegex = /^[0-9]{10}$/
      if (!mobileNumberRegex.test(mobileNumber)) {
        setMobileError(true)
        valid = false
      } else {
        setMobileError(false)
      }
    }

    if (otp === '') {
      setOtperror(true)
      valid = false
    } else {
      setOtperror(false)
    }

    if (isAuthorized === false) {
      setCheckboxError(true)
      valid = false
    } else {
      setCheckboxError(false)
    }

    if (varifyOtp == false) {
      setOtperror(true)
      valid = false
    } else {
      setOtperror(false)
    }

     if (valid) {
       doSubmit()
     }
   
  }

  const handleloginpage = () => {
    courseHeightClick(true)
    setloginpage(true)
    setIsAdmissionpage(false)
  }
  const handleAdmissionpage = () => {
    courseHeightClick(false)
    setloginpage(false)
    setIsAdmissionpage(true)
  }

  const Authentication = () => {
    return new Promise((resolve, reject) => {
      let request = {
        url: 'Authentication/token',
        data: {
          username: 'superadmin1',
          password: 'MTIzNDU=', 
        },
      }
      setShowHideLoader(true)
      api
        .post(request)
        .then((res) => {
          console.log(res)
          if (res.data.success) {
            console.log(res.data.domain_Info.accessKey)
            const token = res.data.domain_Info.accessKey
            setAccessKey(token)
            resolve(token)
            setShowHideLoader(false)
          } else {
            setShowHideLoader(false)
            reject(new Error('Authentication failed'))
          }
        })
        .catch((error) => {
          setShowHideLoader(false)
          reject(error)
        })
    })
  }

  const disciplineAndProgram = (tokenValue) => {
    let request = {
      url: 'Products/all',
      token: `${tokenValue}`,
    }
    setShowHideLoader(true)
    api
      .getAuth(request)
      .then((res) => {
        console.log(res)
        SetProduct(res.data.categories)
        setShowHideLoader(false)
      })
      .catch((error) => {
        console.error('Failed to fetch products', error)
        setShowHideLoader(false)
      })
  }

  const stateAndCity = (tokenValue) => {
    let request = {
      url: 'Geography/state_cities',
      token: `${tokenValue}`,
    }
    setShowHideLoader(true)
    api
      .getAuth(request)
      .then((res) => {
        console.log(res)
        console.log(res.data.states)
        setCityState(res.data.states)
        setShowHideLoader(false)
      })
      .catch((error) => {
        console.error('Failed to fetch products', error)
        setShowHideLoader(false)
      })
  }

  useEffect(() => {
    Authentication().then((token) => {
      disciplineAndProgram(token)
      stateAndCity(token)
    })

    loadCaptchaEnginge(6)
    //  getApiProduct();
    //  getCityState();
  }, [])

  useEffect(() => {
    if (isDisabled) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 1) {
            clearInterval(timer)
            setIsDisabled(false)
            setLableNameOtp('Resend OTP')
            return 12
          }
          return prevCountdown - 1
        })
      }, 1000)
    }
    return () => clearInterval(timer)
  }, [isDisabled])

  useEffect(() => {
    if (otp.length >= 5) {
      varifiedOtp(otp)
    }
  }, [otp])

  //  const getApiProduct = async () => {
  //    let url = "Products/all";
  //    try {
  //      const res = await api.get(url);
  //      console.log(res, "rssssproduct");
  //      SetProduct(res.data?.categories);
  //    } catch (error) {
  //      console.error("Error fetching products:", error);
  //    }
  //  }
  //  const getCityState = async () => {
  //    let url = "Geography/state_cities";
  //    try {
  //      const res = await api.get(url);
  //      setCityState(res.data?.states);
  //    } catch (error) {
  //      console.error("Error fetching products:", error);
  //    }
  //  };

  const genarateOtp = () => {
    console.log(email)
    if (validateEmail(email)) {
      // setCountdown(120);
      setLableNameOtp('Check email for OTP')
      let request = {
        url: '/Verifiers/generate_otp',
        data: {
          receiverAddress: email,
          receiverChannel: 'EM',
          idTenant: 'HRIT',
          token: 'string',
          verifierProperties: 'string',
        },
        token: `${accessKey}`,
      }
      setShowHideLoader(true)
      api.postOTP(request).then((res) => {
        console.log(res.data.domain_Info)
        if (res.data.domain_Info == 'Success') {
          setIsDisabled(true)
          setCountdown(12)
          setShowHideLoader(false)
        }
      })
    } else {
      setEmailError(true)
      setShowHideLoader(false)
    }
  }

  const varifiedOtp = (otp) => {
    let request = {
      url: '/Verifiers/validate_otp',
      data: {
        receiverAddress: email,
        otp: otp,
        tenantId: 'HRIT',
        receiverChannel: 'EM',
      },
      token: `${accessKey}`,
    }
    setShowHideLoader(true)
    api
      .postOTP(request)
      .then((res) => {
        console.log(res)
        if (res.data.displayMessage == 'OTP validated Successfully') {
          setIsAuthorized(true)
          setOtperror(false)
          setLableNameOtp('OTP Verified')
          setCountdown(12)
          setIsDisabled(false)
          setVarifyOtp(true)
          clearInterval(timer)
          setShowHideLoader(false)
          // alert(res?.data?.displayMessage);
        } else {
          setIsAuthorized(false)
          setOtperror(true)
          setLableNameOtp('Resend OTP')
          setCountdown(12)
          setIsDisabled(false)
          clearInterval(timer)
          setShowHideLoader(false)
          // alert(res?.data?.displayMessage);
        }

        // alert(res?.data?.displayMessage);
      })
      .catch((error) => {
        console.log(error)
        setShowHideLoader(false)

        // alert(error?.response?.data?.displayMessage);
      })
  }
  // const handleDisciplineChanges = (event) => {
  //   const selectedEducation = event;
  //   console.log(event, product, "eventdata");
  //   setHigherQualification(selectedEducation);
  //   const selectedData = product.find(
  //     (item) => item.name === selectedEducation
  //   );
  //   setCourses(selectedData ? selectedData.articles : []);
  // };

  const handleDisciplineChanges = (e) => {
    let selectedState = e.target.value
    if(selectedState==='Select'){
      setProductError(true)
    }else{
      setProductError(false)
    }
    setHigherQualification(selectedState)
    console.log(product)
    let selectparticularProgram = product.find((nam) => nam.name == selectedState)
    setParticularProgram(selectparticularProgram)
    // const selectCity=cityState.filter(state => state.name === selectedState)
    // console.log(selectCity)
    //
  }

  const handleStateChanges = (e) => {
    let selectedData = e.target.value
    if (selectedData===''){
      setStateError(true)
    }
    else{
      setStateError(false)
    }
    setState(selectedData)
    let selectparticularState = cityState.find((nam) => nam.stateName == selectedData)
    setParticularCity(selectparticularState)
    setCity('');
  }

  const handleCityChange = (e) => {
    const selectedCity = e.target.value;
    if (selectedCity === '') {
      setCityError(true);
    } else {
      setCityError(false);
    }
    setCity(selectedCity);
  };
  

  const handleProgrameChange = (e) => {
    let selectProgram = e.target.value
    if (selectProgram===''){
    
      setCourseAppliedError(true);
    }
    else{
   
      setCourseAppliedError(false);
    }
    setCourseApplied(selectProgram);
  }

  const handleCaptchaChange = (e) => {
    setCaptcha(e.target.value)
  }
  // const handleStateChanges = (event) => {
  //   const selectedState = event;
  //   setState(selectedState);
  //   const selectedData = cityState.find(
  //     (item) => item.stateName === selectedState
  //   );
  //   setCityList(selectedData ? selectedData.cities : []);
  // };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailPattern.test(email)
  }
  const setStateData = (type, value) => {
    if (type === 'mobileNumber') {
      if (varifyOtp == true) setVarifyOtp(false)
      setOtp('')
      setLableNameOtp('Send OTP')
      const mobileNumberRegex = /^[0-9]{0,10}$/
      if (mobileNumberRegex.test(value)) {
        setMobileNumber(value);
      }
    }
    if (type === 'name') {
      const nameRegex = /^[a-zA-Z ]{0,50}$/
      if (nameRegex.test(value)) {
        setName(value);
      }
    }
    if (type === 'email') {
      const emailRegex = /^[a-zA-Z0-9.@_-]*$/
      if (emailRegex.test(value)) {
        setEmail(value);
      }
    }
  }
  // const handleKeyUp = (event) => {
  //   const value = event.target.value;
  //   setEmail(value);

  //   if (!validateEmail(value)) {
  //     setEmailError(true);
  //   } else {
  //     setEmailError(false);
  //   }
  // };
  const handleBlur = (event) => {
    const value = event.target.value
    setEmail(value)
    if (!validateEmail(value)) {
      setEmailError(true)
    } else {
      setEmailError(false)
    }
  }
  const handlenamekeyup = (event) => {
    const value = event.target.value
    setName(value)
    if (value === '') {
      setNameError(true)
    } else {
      setNameError(false)
    }
  }
  const handlephonenokeyup = (event) => {
    const value = event.target.value
    setMobileNumber(value)
    if (value === '') {
      setMobileError(true)
    } else {
      setMobileError(false)
    }
  }
 



  const doSubmit = () => {
    setShowHideLoader(true)
    // <Popup/>
    let request = {
      url: 'Inquiry/submit',
      data: {
        contactName: name,
        mobileNo: mobileNumber,
        emailId: email,
        Product: courseApplied,
        leadProperties: {
          State: state,
          City: city,
        },
      },
      token: `${accessKey}`,
    }
    api
      .postSubmit(request)
      .then((res) => {
        console.log(res);
        setInquiryNO(res.data.domain_Info.inquiryNO)
        setIsClose(false)
        setShowHideLoader(false)
        // alert(res?.data?.displayMessage);
        // window.location.reload(true);
      })
      .catch((err) => {
        console.error(err)
        setSubmitErrorMessgeShow(err)
        setShowHideLoader(false)
        // alert("There was an error submitting the inquiry. Please try again.");
      })
  }

  return (
    <div>
      {/* <Loader/> */}
      {showHideLoader ? <Loader/>: ' '}
      {!isClose && (
        <Popup setloginpage={setloginpage} setIsAdmissionpage={setIsAdmissionpage} isClose={isClose} setIsClose={setIsClose} inquiryNO={inquiryNO} />
      )}
      {isAdmissionpage ? (
        <div className="containadmissionform">
          <div className="containadmissionformMid">
            <div className="admissionFormLoginBtn">
              <button type="button" id="admissionFormRegisterBtn" onClick={handleAdmissionpage}>
                Register
              </button>
              <button type="button" id="admissionFormLoginBtn" onClick={handleloginpage}>
                Login
              </button>
            </div>

            <Box onSubmit={handleSubmit} className="boxform">
              <div className="formdetails">
                <div className="form-group">
                  <label htmlFor="name">
                    Full Name <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                  </label>
                  <TextField
                    error={nameError}
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setStateData('name', e.target.value)}
                    placeholder="Enter Full Name"
                    onKeyUp={handlenamekeyup}
                    helperText={nameError ? 'Please enter valid name' : ''}
                    size="small"
                    className="errormsg fullNameInputBox"
                    //  sx={{ width:95}}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">
                    Email <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                  </label>
                  <TextField
                    error={emailError}
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setStateData('email', e.target.value)}
                    placeholder="Enter Email"
                    // onKeyUp={handleKeyUp}
                    onBlur={handleBlur}
                    helperText={emailError ? 'Please enter valid email' : ''}
                    size="small"
                    className="errormsg"
                    //  sx={{ width:200}}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="mobileNumber">
                    Mobile Number <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                  </label>

                  <div className="mobile-number-input">
                    <select value="+91" className="mobileCodeadmission">
                      <option value="+91">+91</option>
                    </select>
                    <TextField
                      className="errormsgMobileInputBox"
                      error={mobileError}
                      type="text"
                      id="mobileNumber"
                      value={mobileNumber}
                      onKeyUp={handlephonenokeyup}
                      onChange={(e) => setStateData('mobileNumber', e.target.value)}
                      placeholder="Enter Mobile Number"
                      helperText={mobileError ? 'Please enter valid mobile number' : ''}
                      size="small"
                      //  sx={{ width:150}}
                      required
                    />
                  </div>
                  <div div>
                    {mobileNumber.length == 10 && (
                      <>
                        {!varifyOtp && !isDisabled && (
                          <span
                            style={{
                              fontSize: '11px',
                              fontWeight: '400',
                              color: '#065AA9',
                              display: 'flex',
                              marginTop: '4px',
                              flexDirection: 'row-reverse',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              genarateOtp()
                            }}
                          >
                            {lableNameOtp}
                          </span>
                        )}
                        {isDisabled && (
                          <span
                            style={{
                              fontSize: '11px',
                              fontWeight: '400',
                              color: '#065AA9',
                              display: 'flex',
                              marginTop: '6px',
                              marginBottom: '4px',
                              flexDirection: 'row-reverse',
                            }}
                            id="timer"
                          >
                            Resend OTP in {countdown} seconds
                          </span>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="otp">
                    OTP <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                  </label>
                  <TextField
                    // className="mobileNumberInputBox"
                    error={otperror}
                    type="text"
                    id="otp"
                    value={otp}
                    maxLength={6}
                    disabled={varifyOtp}
                    onChange={(e) => {
                      setOtp(e.target.value)
                    }}
                    placeholder="Enter OTP"
                    helperText={otperror ? 'Please enter valid OTP' : ''}
                    className="errormsg optInputBox"
                    size="small"
                    required
                  />
                  {varifyOtp && (
                    <div
                      style={{
                        marginTop: '4px',
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          color: '#1181D2',
                          fontSize: '12px',
                          marginLeft: '4px',
                        }}
                      >
                        Verified
                      </span>
                      <img style={{ height: '12px', width: '12px' }} src={varified} />
                    </div>
                  )}
                </div>

                <div
                  className="form-group"
                  // style={{ marginTop: `${varifyOtp ? "0" : "-16px"}` }}
                >
                  <label htmlFor="higherQualification">
                    Select Discipline <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                  </label>
                  <TextField
                    error={productError}
                    id="higherQualification"
                    select
                    value={higherQualification}
                    onChange={handleDisciplineChanges}
                    //  onChange={(e) => handleDisciplineChanges(e.target.value)}
                    helperText={productError ? 'Select valid Discipline' : ''}
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    required
                    className="custom-select errormsg"
                  >
                    <option value="Select">Select Discipiline</option>
                    {product?.map((discipiline) => (
                      <option key={discipiline.id} value={discipiline.name}>
                        <span style={{ fontSize: '12px', color: 'red' }}>{discipiline.name}</span>
                      </option>
                    ))}
                  </TextField>
                </div>
                <div
                  className="form-group"
                  // style={{ marginTop: `${varifyOtp ? "0" : "-16px"}` }}
                >
                  <label htmlFor="courseApplied">
                    Select Program <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                  </label>
                  <TextField
                    error={courseAppliedError}
                    id="courseApplied"
                    select
                    size="small"
                    onChange={handleProgrameChange}
                    // value={courseApplied}
                    // onChange={(e) => setCourseApplied(e.target.value)}
                    // sx={{ width:200}}
                    helperText={courseAppliedError ? 'Select Valid Program' : ''}
                    SelectProps={{
                      native: true,
                    }}
                    required
                    className="custom-select errormsg"
                  >
                    <option value="">Select Program</option>
                    {particularProgram.articles?.map((program) => (
                      <option key={program.id} value={program.name}>
                        {program.name}
                      </option>
                    ))}
                  </TextField>
                </div>

                <div className="form-group">
                  <label>
                    State{' '}
                    <span color="red">
                      <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                    </span>
                  </label>
                  <TextField
                    error={stateError}
                    id="selectState"
                    select
                    // value={state}
                    // onChange={(e) => handleStateChanges(e.target.value)}
                    helperText={stateError ? 'Select Valid State' : ''}
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    required
                    className="custom-select errormsg"
                    onChange={handleStateChanges}
                    // sx={{ width:200}}
                  >
                    setState
                    <option value="">Select Your State</option>
                    {cityState?.map((state) => (
                      <option key={state.stateId} value={state.stateName}>
                        {state.stateName}
                      </option>
                    ))}
                  </TextField>
                </div>
                <div className="form-group">
                  <label>
                    City <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                  </label>
                  <TextField
                    error={cityError}
                    id="cityList"
                    select
                    // value={city}
                    onChange={handleCityChange}
                    helperText={cityError ? 'Select Valid City' : ''}
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    required
                    className="custom-select errormsg"
                    // sx={{ width:200}}
                  >
                    <option value="">Select Your City</option>
                    {particularCity.cities?.map((city) => (
                      <option key={city.cityId} value={city.cityName}>
                        {city.cityName}
                      </option>
                    ))}
                  </TextField>
                </div>

                <div className="form-group">
                  <LoadCanvasTemplate />
                </div>
                <div className="form-group">
                  <label htmlFor="name">
                    Enter Captcha <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                  </label>
                  <TextField
                    error={captchaError}
                    required
                    placeholder="Enter Captcha Value"
                    helperText={captchaError ? captchErrorMessgeShow : ''}
                    style={{ width: '98%' }}
                    size="small"
                    id="user_captcha_input"
                    className="errormsg"
                    name="user_captcha_input"
                    type="text"
                    value={captcha}
                    onChange={handleCaptchaChange}
                    // onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>

              <div className="formSubmit" style={{ marginTop: '12px' }}>
                <input type="checkbox" id="isAuthorized" checked={isAuthorized} onChange={(e) => setIsAuthorized(e.target.checked)} />
                <label htmlFor="isAuthorized" style={{ textAlign: 'justify', display: 'grid' }}>
                  <span>
                    {' '}
                    <span style={{ color: 'red' }}>*</span> I authorize HRIT University to contact me with updates and notifications, which overrides
                    DND/NDNC registration.
                  </span>
                  <span style={{ color: '#D73232' }}>{checkboxError ? 'Please Check the Agreement Checkbox to Proceed' : ''}</span>
                </label>
              </div>
              <button type="submit" onClick={handleSubmit}>
                Submit
               
              </button>
            </Box>
          </div>
        </div>
      ) : (
        <Login setShowHideLoader={setShowHideLoader} courseHeightClick={courseHeightClick} />
      )}
    </div>
  )
}

export default Admissionform
