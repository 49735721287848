import React, { useState } from 'react';
import './Header.css';

const Header = () => {
    const [activeItem, setActiveItem] = useState('Dashboard');
  return (
    <div className="header">
      <ul>
        <li  className={activeItem === 'Dashboard' ? 'active' : ''}
        onClick={() => setActiveItem('Dashboard')}><span>Dashboard</span></li>
        <li  className={activeItem === 'MyProfile' ? 'active' : ''}
        onClick={() => setActiveItem('MyProfile')}>My Profile</li>
        <li  className={activeItem === 'AvailableCourses' ? 'active' : ''}
        onClick={() => setActiveItem('AvailableCourses')}>Available Courses</li>
        <li  className={activeItem === 'MyQueries' ? 'active' : ''}
        onClick={() => setActiveItem('MyQueries')}>My Queries</li>
        <li  className={activeItem === 'CommunicationHistory' ? 'active' : ''}
        onClick={() => setActiveItem('CommunicationHistory')}>Communication History</li>
      </ul>
    </div>
  );
};

export default Header;
