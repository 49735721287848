import React from 'react';
 
import './Dashboard.css';

import PrimarySearchAppBar from '../newNavbartop/Navbar';
import Sidebar from '../sideBarNew/Sidebar';
import Header from '../headerNew/Header';
import Inquiry from '../inquiryTableNew/inquiryTable';
import Instruction from '../../Instructions';
import BasicDetail from '../myProfile/BasicDetail';

// import Navbar from './Navbar';

 


const Dashboard = () => {
  
  return (
    <div className="dashboard">
    <PrimarySearchAppBar/>
    
      <div className="main-content">
        
       <Sidebar/>
      <div className="content">
      
        <Header/>
        <Inquiry/>
       
 
   
        </div>
      
      </div>
     
     
     
     
    {/* <img src="/images/admin.svg"/> */}
    </div>
  );
};

export default Dashboard;
